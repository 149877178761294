import { useEffect, useState } from "react";

/**
 * This can be used for simple visibility mounting animation delays
 * @param sourceState the current source mounting state
 * @param delay the delay in miliseconds until the component should be unmounted
 * @returns
 */
export function useTransitionDelay(sourceState: boolean, delay: number) {
  const [delayedValue, setDelayedValue] = useState(sourceState);
  useEffect(() => {
    if (sourceState) {
      // single frame delay on truthy value to ensure css transitions start
      requestAnimationFrame(() => setDelayedValue(sourceState));
      return;
    }
    const id = setTimeout(() => {
      setDelayedValue(sourceState);
    }, delay);
    return () => clearTimeout(id);
  }, [sourceState, delay]);
  return {
    isMounted: sourceState || delayedValue,
    isVisible: sourceState && delayedValue,
  };
}
