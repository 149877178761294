import React, { useCallback, useState } from "react";
import * as Select from "@radix-ui/react-select";
import { DEFAULT_LOCALE } from "@wbly/common";
import * as Flags from "@wbly/tokens/flag";
import { useTransitionDelay } from "@wbly/ui/hooks";
import { Icon } from "@wbly/ui/Icon";
import { iconsaxX } from "@wbly/tokens/icon";
import type {
  CountryPickerCountry,
  CountryPickerProps,
} from "./CountryPicker.types.ts";

import styles from "./CountryPicker.module.css";

function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

const { CountryPickerLang } = styles;

export function CountryPicker({
  currentCountry,
  countries,
  changeCountryLabel,
  otherCountryLabel,
}: CountryPickerProps) {
  const onChange = React.useCallback((value: string) => {
    const url = new URL(window.location.href);
    url.pathname = value === DEFAULT_LOCALE ? "" : value;
    window.location.href = url.pathname;
  }, []);
  const [isOpen, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), []);
  const { isMounted, isVisible } = useTransitionDelay(isOpen, 300);
  return (
    <Select.Root
      value={currentCountry.path}
      onValueChange={onChange}
      open={isOpen}
      onOpenChange={setOpen}
    >
      <Select.Trigger
        className={`${styles.CountryPicker} ${CountryPickerLang}`}
        aria-label={changeCountryLabel}
      >
        <Select.Value>
          <Country
            name={
              <>
                <span className="u-hide@small">
                  {currentCountry.code.toLocaleUpperCase()}
                </span>
                <span className="u-hide@medium">
                  {currentCountry.localisedName}
                </span>
              </>
            }
            code={currentCountry.code}
          />
        </Select.Value>
      </Select.Trigger>
      <Select.Portal>
        <>
          {isMounted && (
            <div
              className={styles["CountryPicker-overlay"]}
              data-open={isVisible}
            />
          )}
          <Select.Content
            className={styles["CountryPicker-content"]}
            position="popper"
          >
            <button
              type="button"
              className={styles["CountryPicker-close"]}
              onClick={handleClose}
            >
              <Icon href={iconsaxX} />
            </button>
            <Select.ScrollUpButton />
            <Select.Viewport className={styles["CountryPicker-viewport"]}>
              <Select.Group>
                <Select.Label className={styles["CountryPicker-title"]}>
                  {changeCountryLabel}
                </Select.Label>
                {countries.map((item) => (
                  <CountryPickerItem {...item} key={item.path} />
                ))}
                <CountryPickerItem
                  code=""
                  localisedName={otherCountryLabel}
                  path={`/${currentCountry.path}/choose-your-country`}
                />
              </Select.Group>
            </Select.Viewport>
            <Select.ScrollDownButton />
          </Select.Content>
        </>
      </Select.Portal>
    </Select.Root>
  );
}

function CountryPickerItem({
  path,
  localisedName,
  code,
}: CountryPickerCountry) {
  return (
    <Select.Item value={path} className={styles["CountryPicker-item"]}>
      <Select.ItemText>
        <Country name={localisedName} code={code} />
      </Select.ItemText>
      <Select.ItemIndicator className={styles["CountryPicker-indicator"]} />
    </Select.Item>
  );
}

function Country({ name, code }: { name: React.ReactNode; code: string }) {
  const iso = code.toUpperCase();
  const flag = iso in Flags ? Flags[iso] : Flags.GLOBE_EU;
  return (
    <span className={styles["CountryPicker-value"]}>
      <span className={styles["CountryPicker-flag"]}>
        {
          <img
            src={flag}
            alt={code ? getFlagEmoji(code) : "🌎"}
            width="24"
            height="24"
            role="presentation"
            loading="lazy"
          />
        }
      </span>
      <span className={styles["CountryPicker-label"]}>{name}</span>
    </span>
  );
}
